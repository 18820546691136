var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseenter":function (e) {
      _vm.hover = true;
    },"mouseleave":function (e) {
      _vm.hover = false;
    },"mousemove":_vm.onMouseMove}},[(_vm.hover && _vm.loadStatus && !_vm.error)?_c('div',{staticStyle:{"width":"200px","height":"125px","overflow":"hidden"}},[_c('div',{staticClass:"progress-bar",style:('height:' + _vm.prh + 'px;border-width: ' + Math.floor((_vm.prh - 2) / 2) + 'px 8px;')},[_c('span',{ref:"pspan",style:('width:' + _vm.progress + '%')})]),(_vm.loadStatus)?_c('div',{staticClass:"u2b-cover",style:('background-image: url(/proxy/u2b/i9ytimg/sb/' +
          _vm.imgUrl.replace('$N', 'M' + _vm.pn) +
          ');background-position: ' +
          _vm.x +
          'px ' +
          _vm.y +
          'px;background-size: ' +
          parseInt(_vm.data[3]) * _vm.width +
          'px;width: ' +
          _vm.width +
          'px;height: ' +
          (_vm.width / 16) * 9 +
          'px')}):_vm._e()]):(_vm.hover && !_vm.loadStatus)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],style:('width:200px;height:125px;background:url(/images/covers/' + _vm.coverImage + ') center center no-repeat;background-size:100% 100%')}):_c('div',{style:('width:200px;height:125px;background:url(/images/covers/' + _vm.coverImage + ') center center no-repeat;background-size:100% 100%')})])}
var staticRenderFns = []

export { render, staticRenderFns }