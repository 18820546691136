import { render, staticRenderFns } from "./BilibiliCover.vue?vue&type=template&id=7f891661&scoped=true&"
import script from "./BilibiliCover.vue?vue&type=script&lang=js&"
export * from "./BilibiliCover.vue?vue&type=script&lang=js&"
import style0 from "./BilibiliCover.vue?vue&type=style&index=0&id=7f891661&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f891661",
  null
  
)

/* custom blocks */
import block0 from "./BilibiliCover.vue?vue&type=custom&index=0&blockType=i18nf"
if (typeof block0 === 'function') block0(component)

export default component.exports