import { render, staticRenderFns } from "./Subscribed.vue?vue&type=template&id=504c40b2&scoped=true&"
import script from "./Subscribed.vue?vue&type=script&lang=js&"
export * from "./Subscribed.vue?vue&type=script&lang=js&"
import style0 from "./Subscribed.vue?vue&type=style&index=0&id=504c40b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504c40b2",
  null
  
)

/* custom blocks */
import block0 from "./Subscribed.vue?vue&type=custom&index=0&blockType=i18nf"
if (typeof block0 === 'function') block0(component)

export default component.exports